

















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private cTab = "黑睛特征参数";
  private data: any = {
    黑睛特征参数: [
      {
        字段名称: "颜色",
        字段英文名称: "colour",
        字段释义:
          "黑睛结构明晰，色彩浅淡--急性炎症，属中医气血虚候；黑睛色彩浓厚、变暗---提示慢性疾病:属中医气滞血瘀候；黑睛色彩浅淡、中央部分呈现昏暗区，可能是严重疾病和损伤造成，也可考虑为预示癌变的警示信号。",
        值域范围说明: "正常、变浅、变深、不均匀",
      },
      {
        字段名称: "质地",
        字段英文名称: "texture",
        字段释义:
          "虹膜的密度和厚度是遗传决定的，用来衡量人体的体力和精力状况。",
        值域范围说明: "1度、2度、3度、4度、5度",
      },
      {
        字段名称: "坑洞",
        字段英文名称: "pit",
        字段释义:
          "膜溃疡达到深层，使角膜基质层溶解坏死，导致角膜组织缺损、变薄，形成溃疡凹陷区。这种病变可能是由于角膜外伤、感染、过敏等因素导致角膜发生炎症，炎症持续发展使角膜逐渐溶解坏死，从而形成坑洞。",
        值域范围说明:
          "有、无、位置（1点、2点、3点、4点、5点、6点、7点、8点、9点、10点、11点、12点）",
      },
      {
        字段名称: "辐射状黑线",
        字段英文名称: "Radiant black line",
        字段释义:
          "常呈扇形或日光射线状，提示身体相应节段有某种程度的精神紧张，如手术后、伤后或治疗后神经性疼痛。",
        值域范围说明:
          "有、无、位置（1点、2点、3点、4点、5点、6点、7点、8点、9点、10点、11点、12点）、全眼",
      },
      {
        字段名称: "同心圆状白线",
        字段英文名称: "Concentric circular white line",
        字段释义:
          "提示疼痛性痉挛及挛缩。若白色同心环位于左眼黑睛上，尤其出现在脑区颞部。宜考虑心脏异常的可能性，特别是黑睛上出现5个以上的白色同心环时，可怀疑心绞痛、心肌梗死、肾绞痛、胆绞痛、肝区疼病有可能性发生。",
        值域范围说明:
          "有、无、局部（位置1点、2点、3点、4点、5点、6点、7点、8点、9点、10点、11点、12点）、全部（1圈、2圈、3圈、4圈及以上）",
      },
      {
        字段名称: "黑斑黑点",
        字段英文名称: "Black spots and spots",
        字段释义:
          "黑睛中的黑斑黑点可能是以下几种情况：巩膜色素痣、虹膜色素痣、飞蚊症、眼内异物、结膜色素痣。",
        值域范围说明:
          "有、无，位置（1点、2点、3点、4点、5点、6点、7点、8点、9点、10点、11点、12点）",
      },
      {
        字段名称: "代谢环(环一）",
        字段英文名称: "Metabolic ring",
        字段释义:
          "黑睛中的代谢环是指瞳孔周边的部分，也可以认为是依附于虹膜上的瞳孔边缘部，代谢环实际上是前后色素层的虹膜后面上皮层向前翻转，卷成略隆起的色素边缘。",
        值域范围说明: "正常、偏斜、消失、不均匀",
      },
      {
        字段名称: "肠道环(环二、环三）",
        字段英文名称: "Intestinal ring",
        字段释义:
          "黑睛中的肠道环是一种中医目诊中的观察指标，它指的是黑睛（角膜）周围的一圈环形区域。根据中医理论，肠道环可以反映人体肠道的健康状况。",
        值域范围说明: "正常、扩大、缩小",
      },
      {
        字段名称: "环七",
        字段英文名称: "Huanqi",
        字段释义:
          "黑睛中的环七，又称七色环或七轮环，环七是指黑睛（角膜）周围呈现出的七个环形区域，每个区域对应着不同的脏腑器官。",
        值域范围说明: "正常、皮垢环、蓝环、老化弧，钠环",
      },
    ],
    白睛脉络特征参数: [
      {
        字段名称: "位置",
        字段英文名称: "position",
        字段释义:
          "白睛，又称白眼、白仁、白珠等，在中医五轮学说中被称为气轮。它位于黑睛的外侧，占据眼球壁外层的后5/6部分。白睛的表层由一些纤细的纤维和弹性组织构成，含有较多的小血管。",
        值域范围说明: "上、下、左上、右上、左下、右下、左、右",
      },
      {
        字段名称: "颜色",
        字段英文名称: "colour",
        字段释义:
          "白睛的颜色变化可以反映人体内脏的健康状况。正常情况下，白睛的颜色应该是清白洁净的，这表示身体健康无病。然而，当白睛的颜色发生变化时，可能提示着内脏有疾病存在。",
        值域范围说明: "深红、鲜红、淡红、淡紫、淡青",
      },
      {
        字段名称: "形状",
        字段英文名称: "shape",
        字段释义:
          "正常情况下，白睛应该呈现出清晰、光滑、均匀的形态。然而，当白睛的形状发生变化时，可能提示着眼睛或身体其他部位存在疾病。",
        值域范围说明:
          "根部粗大、曲张、怒张、延伸、离断、分叉、隆起、雾斑、黑点黑圈、螺旋状、蜘蛛网状、叶脉状、横行血丝、血脉贯通",
      },
      {
        字段名称: "走行",
        字段英文名称: "run",
        字段释义:
          "白睛走行是指白睛（巩膜）上血管的形态和分布情况。在中医眼科中，通过观察白睛走行可以了解人体气血的运行状态和内脏的健康状况。正常情况下，白睛上的血管应该呈现出清晰、均匀、柔和的形态，走向自然，没有扭曲、扩张或缩窄等现象。",
        值域范围说明: "走向瞳孔、远离瞳孔",
      },
      {
        字段名称: "特殊眼征",
        字段英文名称: "Special eye signs",
        字段释义:
          "白睛中的特殊眼征是指在白睛部位出现的一些异常征象，这些征象可能提示着人体内部脏腑器官的疾病或病变。",
        值域范围说明: "巩膜胃征、巩膜痔征、巩膜肝炎征",
      },
    ],
  };
}
